import React from "react"
import { graphql } from "gatsby"
// import { RenderModules } from 'src/utils/renderModules'
import SEO from "src/components/SEO"
import Header from "src/components/Header"
import Footer from "src/components/Footer"
import ComponentRenderer from "src/components/ComponentRenderer"
import FeaturedPostSlider from "src/components/FeaturedPostSlider"
import ArticleGrid from "src/components/ArticleGrid"
import { getBackupShareImage } from "src/utils/getBackupShareImage"

const Blog = ({ data, pageContext }) => {
  const page = data?.sanityPage?.content?.main
  const { blogSettings } = page
  const pageMeta = data?.sanityPage?.content?.meta
  const path = page?.slug?.current
  let modules = page?.modules || []
  // Filter out hidden modules
  modules = modules?.filter(module => !module?.hidden)
  const hasAtf =
    modules[0]?._type === "wideMedia" && modules[0]?.width === "fullWidth"
  const shareImage = pageMeta?.shareImage?.asset?.url

  const {
    recentPosts,
    categoryPosts,
    selectedPostsByPost,
    selectedPostsByPage,
  } = data

  let featuredPosts = recentPosts.edges.slice(0, 4)
  let gridPosts = recentPosts.edges

  if (blogSettings?.sliderType === "currated") {
    featuredPosts = blogSettings.featuredPosts
  }

  if (categoryPosts?.edges?.length > 0) {
    gridPosts = categoryPosts.edges
  }

  if (
    selectedPostsByPost?.edges?.length > 0 ||
    selectedPostsByPage?.edges?.length > 0
  ) {
    const unSortedPosts = [
      ...selectedPostsByPost?.edges,
      ...selectedPostsByPage?.edges,
    ].sort((a, b) => {
      return new Date(b.node._createdAt) - new Date(a.node._createdAt)
    })

    gridPosts = unSortedPosts
  }

  return (
    <>
      <SEO
        pagePath={path}
        title={pageMeta?.metaTitle || page?.title}
        description={pageMeta?.metaDescription}
        keywords={pageMeta?.keywords}
        shareImage={
          shareImage ? shareImage + "?w=800" : getBackupShareImage(modules)
        }
      />
      <Header
        hasAtf={hasAtf}
        location={path}
        hideToggle={!!page.hideCustomerToggle}
      />

      {featuredPosts.length > 0 && (
        <FeaturedPostSlider
          posts={featuredPosts}
          theme="lightGrey"
          nextTheme="default"
          isFirstSection
        />
      )}

      <ArticleGrid
        posts={gridPosts}
        theme="default"
        prevTheme="lightGrey"
        nextTheme={modules[0]?.theme}
        excludedPosts={featuredPosts}
      />

      {modules?.map((item, index) => {
        const prevSection = modules[index - 1]
        const nextSection = modules[index + 1]
        let prevTheme = false
        let nextTheme = false
        if (prevSection && prevSection.width !== "fullWidth") {
          prevTheme = prevSection.theme || "default"
        }
        if (nextSection && nextSection.width !== "fullWidth") {
          nextTheme = nextSection.theme || "default"
        }
        if (!item.theme && item.width !== "fullWidth") {
          item.theme = "default"
        }
        return (
          <ComponentRenderer
            item={item}
            key={item?._key || "section-" + index}
            nextTheme={nextTheme}
            prevTheme={prevTheme}
            isFirstSection={index === 0}
            isLastSection={index === modules.length - 1}
          />
        )
      })}
      <Footer legalText={page?._rawLegalText} />
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!, $categoryId: String, $selectedPostIds: [String]) {
    sanityPage(id: { eq: $id }) {
      ...Page
    }
    categoryPosts: allSanityPost(
      filter: { categories: { elemMatch: { id: { eq: $categoryId } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...Post
        }
      }
    }
    selectedPostsByPost: allSanityPost(
      filter: { id: { in: $selectedPostIds } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...Post
        }
      }
    }
    selectedPostsByPage: allSanityPage(
      filter: { id: { in: $selectedPostIds } }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...Page
        }
      }
    }
    recentPosts: allSanityPost(
      filter: { _id: { regex: "/^((?!drafts).)*$/" } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...Post
        }
      }
    }
  }
`

export default Blog
